<template>
  <router-view v-slot="{ Component }">
    <transition name="el-fade-in-linear">
        <component :is="Component" />
    </transition>
  </router-view>
</template>

<script>
import { reactive, toRefs, onBeforeMount, onMounted, defineComponent } from "vue";
export default defineComponent({
  name: "",
  setup() {
    const data = reactive({});
    onBeforeMount(() => {});
    onMounted(() => {});
    const refData = toRefs(data);
    return {
      ...refData,
    };
  },
});
</script>
<style lang="scss" scoped></style>
